import PlausibleProvider from "next-plausible";

import "../styles/globals.css";
import type { AppProps } from "next/app";

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <PlausibleProvider
            domain="txtio.net"
            selfHosted={true}
            customDomain="https://io.fun-club.xyz"
        >
            <Component {...pageProps} />
        </PlausibleProvider>
    );
}

export default MyApp;
